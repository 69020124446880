import React from "react";
import Typography from "@mui/material/Typography";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const PrivacyModal = ({ open, onClose }) => (
  <Dialog open={open} onClose={onClose} maxWidth="md">
    <DialogTitle> Política de privacidad </DialogTitle>

    <DialogContent>
      <DialogContentText>
        <Typography variant="body1" gutterBottom>
          Respetamos tu privacidad y nos aseguramos de que los datos personales
          que compartas con nosotros sean tratados de forma confidencial. Los
          datos personales recabados en el marco del uso de la herramienta
          CREATE & SHARE sólo se tratarán de acuerdo con los requisitos del
          Reglamento General de Protección de Datos (RGPD).
        </Typography>

        <Typography variant="h6">
          ¿Quién es el responsable del tratamiento de tus datos?
        </Typography>
        <Typography variant="body1" gutterBottom>
          LEMONLAND, S.L. con domicilio social Príncipe de Vergara, 112
          (edificio Utopicus), C.P. 28002, Madrid; NIF B01787886; Contacto:{" "}
          <a href="mailto:hello@lemonland.es">hello@lemonland.es</a>
        </Typography>

        <Typography variant="h6">
          ¿Con qué finalidades vamos a tratar tus datos personales?
        </Typography>
        <Typography variant="body1" gutterBottom>
          LEMONLAND, S.L. trata los datos necesarios proporcionados mediante la
          cumplimentación de los correspondientes formularios de su producto
          CREATE & SHARE contenidos en la página, de las consultas o emails
          recibidos, así como aquellos datos a los que LEMONLAND acceda como
          consecuencia de su navegación, para el mantenimiento de la relación
          con el Usuario.
        </Typography>

        <Typography variant="body1">
          Tus datos podrán ser tratados con las siguientes finalidades:
        </Typography>
        <ul>
          <li>
            Tratamiento por relación contractual. Usaremos la información que
            nos facilites para el cumplimiento de una relación contractual en
            los siguientes casos:
            <ul>
              <li>
                Creación de una cuenta de Usuario: permitir el acceso y uso del
                panel de administración de la herramienta CREATE & SHARE para la
                creación y publicación de contenidos, y tramitar la resolución
                de dudas y proporcionarle información relacionada con la
                herramienta.{" "}
              </li>
            </ul>
          </li>
          <li>
            Tratamiento por consentimiento expreso. Siempre que haya dado su
            consentimiento expreso trataremos sus datos personales para:
            <ul>
              <li>
                Suscripción al boletín de noticias: el envío de novedades y
                noticias directamente relacionadas con LEMONLAND o su
                herramienta CREATE & SHARE, incluyendo la posibilidad de
                realizar actividades promocionales.{" "}
              </li>
            </ul>
          </li>
        </ul>

        <Typography variant="h6">
          ¿Cuál es la legitimación para el tratamiento de tus datos?
        </Typography>
        <Typography variant="body1" gutterBottom>
          La base legal para el tratamiento de tus datos está fundada en la
          obtención de tu consentimiento, de acuerdo con lo establecido en el
          artículo 6.1.a del Reglamento (UE) 679/2016 mediante la selección de
          la casilla ubicada al efecto en los formularios de recogidas de datos.
          El tratamiento también se encontrará legitimado según lo dispuesto en
          el artículo 6.1.b para la ejecución de una relación contractual que
          vincule al Usuario con la WEB a modo de ejemplo, al registrarse.
        </Typography>

        <Typography variant="h6">
          ¿A qué destinatarios le comunicaremos tus datos?
        </Typography>
        <Typography variant="body1" gutterBottom>
          La información de la que LEMONLAND es Responsable no será cedida a
          terceros, a no ser que una obligación legal así lo establezca, o en
          caso de que contemos con su autorización expresa. No utilizaremos sus
          datos personales con una finalidad diferente a la expresada en esta
          política de privacidad y en los formularios de recogida de
          consentimiento. Por lo tanto, todas aquellas cesiones de datos que se
          efectúen se harán bajo el marco de protección indicado por la Agencia
          Española de Protección de Datos, o siempre y cuando estén consentidas
          expresamente por el Usuario y a los países cuya seguridad y
          confidencialidad esté garantizada.{" "}
        </Typography>

        <Typography variant="h6">
          ¿Se realizarán transferencias internacionales de datos?
        </Typography>
        <Typography variant="body1" gutterBottom>
          LEMONLAND no tiene previsto realizar transferencias internacionales de
          datos. En caso de ser necesarias, sólo se realizarán a entidades bajo
          la habilitación del acuerdo EEUU-Unión Europea Privacy Shield (más
          información:{" "}
          <a href="https://www.privacyshield.gov/welcome">
            https://www.privacyshield.gov/welcome
          </a>
          ), o a entidades que hayan demostrado que cumplen con el nivel de
          protección y garantías de acuerdo con los parámetros y exigencias
          previstas en la normativa vigente en materia de protección de datos,
          como el Reglamento Europeo, o cuando exista habilitación legal para
          realizar la transferencia internacional.{" "}
        </Typography>

        <Typography variant="h6">
          ¿Durante qué plazo almacenaremos tus datos?
        </Typography>
        <Typography variant="body1" gutterBottom>
          Trataremos tus datos, siempre y cuando sean necesarios para la
          finalidad para los que fueron recogidos, mientras dure el tratamiento,
          y durante el tiempo establecido por una obligación legal.
        </Typography>

        <Typography variant="h6">
          ¿Cuáles son los derechos que puedo ejercer?
        </Typography>
        <Typography variant="body1" gutterBottom>
          Una vez que, como Usuario de la WEB nos has facilitado tus datos
          personales, la legislación vigente te permite como interesado de tus
          datos:
          <ul>
            <li>
              Solicitar el acceso a los datos personales relativos al
              interesado, pudiendo conocer si estamos tratando tus datos de
              carácter personal y acceder a tus datos personales.
            </li>
            <li>
              Solicitar la rectificación de los datos en caso de que fuesen
              inexactos.
            </li>
            <li>
              Solicitar la supresión de sus datos en la medida en que ya no son
              necesarios para la finalidad que se comunicó, o en el caso de que
              ya no contemos con la legitimación necesaria para continuar con su
              tratamiento.
            </li>
            <li>
              Solicitar la limitación de su tratamiento, en algunos supuestos,
              por lo que podremos suspender el tratamiento temporalmente o que
              los conservemos más allá del tiempo establecido cuando puedas
              necesitarlo.
            </li>
            <li>
              Oponerse al tratamiento de aquella información que nos ha
              facilitado, salvo que existan motivos legítimos imperiosos para
              que el tratamiento prevalezca.
            </li>
            <li>
              A revocar el consentimiento para cada finalidad específica, sin
              que ello afecte a la licitud del tratamiento basado en el
              consentimiento expreso.
            </li>
            <li>
              Solicitar la portabilidad de los datos, que le serán facilitados
              en un formato estructurado, de uso común o lectura mecánica.
            </li>
            <li>
              A presentar una reclamación ante la Agencia Española de Protección
              de Datos o autoridad de control competente, si cree que no le
              hemos atendido correctamente.
            </li>
          </ul>
        </Typography>
        <Typography variant="h6">¿Cómo puedes ejercer tus derechos?</Typography>
        <Typography variant="body1" gutterBottom>
          Puedes contactar con nosotros a través del correo{" "}
          <a href="mailto:hello@lemonland.es">hello@lemonland.es</a>. Si lo
          prefieres, puedes usar los formularios elaborados por la Agencia
          Española de Protección de Datos o la autoridad competente.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Estos formularios deben ir firmados electrónicamente o ser acompañados
          de la fotocopia del DNI en vigor.
        </Typography>
        <Typography variant="body1" gutterBottom>
          En el caso de representación debe adjuntarnos copia del DNI vigente
          del representante junto con su solicitud.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Los formularios pueden ser presentados enviando un mail a{" "}
          <a href="mailto:hello@lemonland.es">hello@lemonland.es</a>.
        </Typography>
        <Typography variant="body1" gutterBottom>
          En cumplimiento de lo prevenido en el artículo 21 de la Ley 34/2002 de
          servicios de la sociedad de la información y comercio electrónico, si
          usted no desea recibir más información sobre nuestros servicios, puede
          darse de baja enviando un email a{" "}
          <a href="mailto:hello@lemonland.es">hello@lemonland.es</a>
          indicando en el asunto “Baja”.
        </Typography>
        <Typography variant="h6">
          ¿Cuál es el tiempo establecido para dar solución al ejercicio de
          derechos?
        </Typography>
        <Typography variant="body1" gutterBottom>
          Te informaremos sobre las actuaciones derivadas de tu petición en el
          plazo de un mes que podrá extenderse dos meses más cuando se trate de
          solicitudes especialmente complejas y le notificaremos dicha
          ampliación dentro del primer mes.
        </Typography>
        <Typography variant="body1" gutterBottom>
          En aquellos casos que no atendamos tu solicitud, te informaremos de
          ello, motivando su negativa dentro del plazo de un mes desde su
          presentación.
        </Typography>
        <Typography variant="h6">
          ¿Se pueden producir cambios en la política de privacidad?
        </Typography>
        <Typography variant="body1" gutterBottom>
          La WEB se reserva el derecho a modificar la presente política para
          adaptarla a novedades legislativas o jurisprudenciales, así como a
          prácticas de la industria. En dichos supuestos, el Prestador anunciará
          en esta página los cambios introducidos con razonable antelación a su
          puesta en práctica.
        </Typography>
      </DialogContentText>
    </DialogContent>
  </Dialog>
);

export default PrivacyModal;
