import React from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";

import favicon from "./img/favicon.png";

const PoweredBy = ({ sx }) => (
  <Link
    href="https://www.lemonland.es"
    target="_blank"
    underline="none"
    color="inherit"
  >
    <Box
      sx={{
        position: "absolute",
        bottom: 10,
        right: 20,
        padding: "5px 15px",
        fontSize: 10,
        backgroundColor: "#ffffff",
        color: "#20201e",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "&:hover": {
          backgroundColor: "#ffffff",
        },
        borderRadius: 20,
        ...sx,
      }}
    >
      <Box
        component="img"
        src={favicon}
        sx={{
          width: "30px",
          marginRight: 1,
        }}
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          lineHeight: 1.2,
          fontSize: 14,
        }}
      >
        <Box>Powered by</Box>
        <Box sx={{ fontWeight: 600 }}>Lemonland</Box>
      </Box>
    </Box>
  </Link>
);

export default PoweredBy;
