import React from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
// import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";

// import logoUrl from "./img/logo.png";
import desktopBackgroundUrl from "./img/desktop_fondo_landing_sin_logo.svg";
import desktopThanksBackgroundUrl from "./img/desktop_fondo_gracias.svg";
import tabletThanksBackgroundUrl from "./img/tablet_fondo_gracias.svg";
import movilThanksBackgroundUrl from "./img/movil_fondo_gracias.svg";
import avatarGirlUrl from "./img/img_chica_perfil.svg";
import limonesUrl from "./img/limones.svg";
import desktopCompleteGirlUrl from "./img/desktop_img_chica_completa.svg";
import tabletCompleteGirlUrl from "./img/tablet_img_chica_completa.svg";
import csLogoUrl from "./img/logoNegroCS.png";

import PoweredBy from "./components/PoweredBy";
import PrivacyModal from "./components/PrivacyModal";
import TermsModal from "./components/TermsModal";

import axios from "axios";

function makeid(length) {
  var result = "";
  var characters = "abcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export default function Home() {
  // const [name, setName] = React.useState("");
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const desktop = !mobile && !tablet;
  const [email, setEmail] = React.useState("");
  const [privacy, setPrivacy] = React.useState(false);
  const [terms, setTerms] = React.useState(false);
  const [sectionSlug, setSectionSlug] = React.useState(makeid(6));
  const [showThankYou, setShowThankYou] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [privacyModalOpen, setPrivacyModalOpen] = React.useState(false);
  const [termsModalOpen, setTermsModalOpen] = React.useState(false);

  const validate = () => {
    let valid = true;
    const errors = {};
    // if (!name) {
    //   errors.name = "Este campo es obligatorio";
    // }

    // Comprobamos que el email es correcto
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      errors.email = "Introduce un email válido";
      valid = false;
    }

    if (!sectionSlug) {
      errors.sectionSlug = "Este campo es obligatorio";
      valid = false;
    }

    if (!privacy) {
      errors.privacy = "Debes aceptar la política de privacidad";
      valid = false;
    }

    if (!terms) {
      errors.terms = "Debes aceptar los términos y condiciones";
      valid = false;
    }

    setError(errors);

    return valid;
  };

  if (showThankYou) {
    return (
      <Box
        sx={{
          minHeight: "calc(max(100vh, 770px))",
          backgroundImage: {
            xs: `url(${movilThanksBackgroundUrl})`,
            sm: `url(${tabletThanksBackgroundUrl})`,
            md: `url(${desktopThanksBackgroundUrl})`,
          },
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "top center",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box sx={{ fontSize: 32, marginBottom: 1, maxWidth: 1000 }}>
          <Typography
            sx={{
              textAlign: "center",
              padding: 2,
              fontSize: { xs: "7vw", sm: 40 },
              fontWeight: 600,
              lineHeight: 1.2,
            }}
          >
            ¡Muchas gracias!
          </Typography>
        </Box>
        <Box sx={{ fontSize: 22, maxWidth: 1000 }}>
          <Typography
            sx={{
              textAlign: "center",
              padding: 2,
              fontSize: { xs: "4vw", sm: 20 },
            }}
          >
            Te hemos <b>enviado un email</b> para que crees tu contraseña y
            puedas <b>empezar a crear tus contenidos</b>.<br />
            <br />
            Si no lo encuentras en tu bandeja de entrada, revisa tu carpeta de
            spam.
          </Typography>
          <Typography
            sx={{
              textAlign: "center",
              padding: 2,
              fontSize: { xs: "4vw", sm: 16 },
            }}
          ></Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        minHeight: "calc(max(100vh, 770px))",
        backgroundImage: `url(${desktopBackgroundUrl})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "top center",
        position: "relative",
      }}
    >
      <Container maxWidth="md" sx={{ padding: { sm: "0 50px" } }}>
        <Box sx={{ textAlign: "center", padding: 2 }}>
          <Box
            component="img"
            src={csLogoUrl}
            alt="logo"
            sx={{
              maxWidth: "300px",
              marginTop: 5,
              marginBottom: 2,
              width: { xs: "60vw" },
            }}
          />
        </Box>
        {!showThankYou && (
          <Box
            sx={{
              borderRadius: 7,
              border: "2px solid #e7e2dc",
              padding: {
                xs: "20vw 2vw 2vw",
                sm: "60px 80px",
                md: "20px 100px",
              },
              color: "#20201e",
              position: "relative",
            }}
          >
            <Box
              component="img"
              src={avatarGirlUrl}
              alt="avatar"
              sx={{
                position: "absolute",
                top: 0,
                left: { xs: "50%", sm: 0 },
                transform: {
                  xs: "translate(-50%, -30%)",
                  sm: "translate(-20%, -35%)",
                  lg: "translate(-40%, -25%)",
                },
                width: { xs: "30vw", sm: "150px" },
              }}
            />
            <Typography
              sx={{
                textAlign: "center",
                padding: 2,
                fontSize: { xs: "7vw", sm: 40 },
                fontWeight: 600,
                lineHeight: 1.2,
              }}
            >
              Crea y comparte tus contenidos digitales
            </Typography>
            <Typography
              sx={{
                textAlign: "center",
                padding: 2,
                fontSize: { xs: "4vw", sm: 16 },
              }}
            >
              Con <b>Create & Share</b>, un producto de Lemonland, podrás
              hacerlo de una manera fácil e intuitiva. Landings promocionales,
              newsletters, propuestas, comunicaciones... Crea un contenido
              atractivo en pocos minutos y compártelo con quien quieras.
            </Typography>
            <Box
              sx={{
                padding: 2,
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <TextField
                required
                disabled={loading}
                error={!!error.email}
                label="Email"
                type="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setError({ ...error, email: undefined });
                }}
                fullWidth
                sx={{
                  "& label": {
                    color: "#d9cb51",
                  },
                  "& label.Mui-focused": {
                    color: "#d9cb51",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "#d9cb51",
                  },
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: "#fdfce0",
                    borderRadius: 7,

                    "& fieldset": {
                      borderColor: "#d9cb51",
                      borderRadius: 7,
                      borderWidth: 2,
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#d9cb51",
                    },
                    "&:hover fieldset": {
                      borderColor: "#d9cb51",
                    },
                    input: {
                      color: "#20201e",
                    },
                  },
                  marginBottom: 2,
                }}
                helperText={error.email}
              />
              <TextField
                required
                disabled={loading}
                error={!!error.sectionSlug}
                label="Sección"
                value={sectionSlug}
                onChange={(e) => {
                  setSectionSlug(e.target.value);
                  setError({ ...error, sectionSlug: undefined });
                }}
                fullWidth
                helperText={
                  error.sectionSlug ||
                  `La URL de las píldoras que crees y compartas será https://cs.lemonland.es/${sectionSlug}/`
                }
                sx={{
                  marginBottom: 2,
                  display: "none",
                }}
              />
              <FormControl error={!!error.terms}>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{
                        color: "#d9cb51",
                        "&.Mui-checked": {
                          color: "#d9cb51",
                        },
                      }}
                      checked={terms}
                      onChange={(e) => {
                        setTerms(e.target.checked);
                        if (e.target.checked) {
                          setError({ ...error, terms: undefined });
                        }
                      }}
                    />
                  }
                  label={
                    <Box
                      component={"span"}
                      sx={{
                        fontSize: { xs: "3vw", sm: 14 },
                      }}
                    >
                      He leído y acepto los{" "}
                      <Box
                        component="span"
                        onClick={() => setTermsModalOpen(true)}
                        sx={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                      >
                        términos y condiciones
                      </Box>
                    </Box>
                  }
                />
                {error.terms && <FormHelperText>{error.terms}</FormHelperText>}
                <TermsModal
                  open={termsModalOpen}
                  onClose={() => setTermsModalOpen(false)}
                />
              </FormControl>

              <FormControl error={!!error.privacy}>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{
                        color: "#d9cb51",
                        "&.Mui-checked": {
                          color: "#d9cb51",
                        },
                      }}
                      checked={privacy}
                      onChange={(e) => {
                        setPrivacy(e.target.checked);
                        if (e.target.checked) {
                          setError({ ...error, privacy: undefined });
                        }
                      }}
                    />
                  }
                  label={
                    <Box
                      component={"span"}
                      sx={{
                        fontSize: { xs: "3vw", sm: 14 },
                      }}
                    >
                      He leído y acepto la{" "}
                      <Box
                        component="span"
                        onClick={() => setPrivacyModalOpen(true)}
                        sx={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                      >
                        política de privacidad
                      </Box>
                    </Box>
                  }
                />

                {error.privacy && (
                  <FormHelperText>{error.privacy}</FormHelperText>
                )}
                <PrivacyModal
                  open={privacyModalOpen}
                  onClose={() => setPrivacyModalOpen(false)}
                />
              </FormControl>
              <LoadingButton
                variant="contained"
                loading={loading}
                size="large"
                sx={{
                  backgroundColor: "#20201e",
                  letterSpacing: 1,
                  marginTop: 2,
                  "&:hover": {
                    backgroundColor: "#20201e",
                  },
                }}
                onClick={() => {
                  setLoading(true);
                  setError({});

                  if (!validate()) {
                    setLoading(false);
                    return;
                  }

                  axios
                    .post(
                      "https://europe-west1-createandshare.cloudfunctions.net/cs-proposals-new-user",
                      {
                        // name,
                        email,
                        sectionSlug,
                      }
                    )
                    .then((response) => {
                      // console.log("response", response);

                      if (response.status === 200) {
                        setShowThankYou(true);
                      } else {
                        console.error("Error inesperado", response);
                      }

                      setLoading(false);
                    })
                    .catch((e) => {
                      const response = e.response;
                      if (response.status === 409) {
                        const errorResponse = response.data;
                        if (errorResponse.errorCode === "used_email") {
                          setError({ email: errorResponse.message });
                        }

                        if (errorResponse.errorCode === "used_slug") {
                          setError({
                            sectionSlug: errorResponse.message,
                          });
                        }
                      } else {
                        console.error("Error inesperado", e);
                      }
                      setLoading(false);
                    });
                }}
              >
                Empezar
              </LoadingButton>
            </Box>
          </Box>
        )}
        {/* {showThankYou && (
          <Box sx={{ padding: 2, textAlign: "center" }}>
            <Box sx={{ fontSize: 32, marginBottom: 1 }}>¡Muchas gracias!</Box>
            <Box sx={{ fontSize: 22 }}>
              Te hemos enviado un email con los pasos a seguir. Si no lo
              encuentras en tu bandeja de entrada, revisa tu carpeta de spam.
            </Box>
          </Box>
        )} */}
      </Container>

      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          left: 0,
          textAlign: "center",
          width: "100vw",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            position: "relative",
            width: "100%",
            maxWidth: 960,
          }}
        >
          <Box
            component="img"
            src={limonesUrl}
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: { xs: "130px", sm: "200px" },
              // height: "100px",
              transform: {
                xs: "translate(10%, -100%)",
                sm: "translate(10%, -100%)",
                lg: "translate(-70%, -100%)",
              },
            }}
          />
          {desktop && (
            <Box
              component="img"
              src={desktopCompleteGirlUrl}
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
                width: "200px",
                // height: "100px",
                transform: "translate(40%, -100%)",
              }}
            />
          )}
          {tablet && (
            <Box
              component="img"
              src={tabletCompleteGirlUrl}
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
                width: "200px",
                // height: "100px",
                transform: "translate(0%, -100%)",
              }}
            />
          )}
        </Box>
      </Box>
      <PoweredBy />
    </Box>
  );
}
